import React from "react"
import { graphql, Link } from "gatsby"
import groupBy from "lodash/groupBy"
import scrollToElement from "scroll-to-element"
import { FaCertificate, FaStar, FaMusic } from "react-icons/fa"
import Layout from "../components/layout"
import { getTranslationPath } from "../utils/url"
import removeDiacritics from "../utils/removeDiacritics"
import getTranslationRefTitle from "../utils/getTranslationRefTitle"
import SEO from "../components/seo"

export default ({ data }) => {
  const translations = data.allStrapiTranslation.nodes.map(translation => {
    return {
      ...translation,
      plainTitle: removeDiacritics(translation.title).trim(),
    }
  })

  const translationsByFirstLetter = groupBy(
    translations,
    translation => translation.plainTitle[0]
  )

  return (
    <Layout>
      <SEO title="Αρχείο Μεταφράσεων" />
      <article>
        <h1 className="font-semibold font-sans text-gray-900 mb-6 text-3xl md:text-4xl leading-tight">
          Αρχείο Μεταφράσεων - Διασκευών
        </h1>
        <ul className="flex flex-wrap -m-1">
          {Object.keys(translationsByFirstLetter)
            .sort((a, b) => a.localeCompare(b))
            .map(firstLetter => {
              return (
                <li key={firstLetter} className="m-1">
                  <button
                    onClick={() => scrollToElement(`#${firstLetter}`)}
                    className="block w-6 h-6 text-center bg-gray-200 text-gray-600 hover:text-gray-900"
                  >
                    {firstLetter}
                  </button>
                </li>
              )
            })}
        </ul>
        <div className="mt-8">
          {Object.keys(translationsByFirstLetter)
            .sort((a, b) => a.localeCompare(b))
            .map(firstLetter => {
              return (
                <React.Fragment key={firstLetter}>
                  <h5
                    id={firstLetter}
                    className="mt-4 text-gray-600 font-semibold"
                  >
                    {firstLetter}
                  </h5>
                  <ul className="mb-4">
                    {translationsByFirstLetter[firstLetter]
                      .sort((firstTranslation, secondTranslation) =>
                        firstTranslation.plainTitle.localeCompare(
                          secondTranslation.plainTitle
                        )
                      )
                      .map(translation => {
                        return (
                          <li key={translation.id} className="my-2">
                            <Link
                              to={getTranslationPath(translation)}
                              className="text-blue-700 hover:underline"
                            >
                              {getTranslationRefTitle(translation)}
                              {translation.isOfficialTranslation && (
                                <FaCertificate
                                  size={12}
                                  className="ml-1 inline"
                                  title="Επίσημη Μετάφραση"
                                />
                              )}
                              {translation.isApprovedByCA && (
                                <FaStar
                                  size={12}
                                  className="ml-1 inline"
                                  title="Προτείνεται από ΣΕΧΚ"
                                />
                              )}
                              {translation.isMusicSheetAvailable && (
                                <FaMusic
                                  size={12}
                                  className="ml-1 inline"
                                  title="Διατίθεται παρτιτούρα στα ελληνικά"
                                />
                              )}
                            </Link>
                          </li>
                        )
                      })}
                  </ul>
                </React.Fragment>
              )
            })}
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiTranslation(sort: { fields: title, order: ASC }) {
      nodes {
        id: strapiId
        pubyear
        title
        isApprovedByCA
        isOfficialTranslation
        isMusicSheetAvailable
        translators {
          id
          name
        }
        song {
          title: Title
          id
          pubyear
        }
      }
    }
  }
`
